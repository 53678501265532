html,body {
  height:100%;
  width:100%;
  margin:0;
  border:0;
  float:left;
}
p {
  margin:0;
}
h4 {
  font-size:1em;
  font-weight:normal;
  margin:0;
}
ol {
  margin-block-start:0;
  margin-block-end:0;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#home {
  width:90%;
  min-height:97.5%;
  position:relative;
  background-size:cover;
  padding:2.5% 5%;
  float:left;
}
#header {
  background-color: white;
  width:96%;
  padding:1% 2%;
  display:flex;
  justify-content:space-between;
  align-items:center;
}
#header-logo {
  width:5%;
}
#login {
  width:35%;
  text-align:center;
  margin:auto;
  margin-top:5%;
  background-color: rgba(255,255,255);
  padding:2.5% 2.5%;
}
#profile {
  width:65%;
  margin-left:15%;
  margin-top:5%;
  background-color: rgba(255,255,255);
  padding:2.5% 2.5%;
  float:left;
  text-align: center;
}
.fields {
  width:100%;
  float:left;
  display:flex;
  margin-bottom:1%;
  align-items:flex-start;
}
.fields p, small {
  width:30%;
  text-align: right;
  margin-right:1%;
  float:left;
}
.fields h4 {
  width:50%;
  text-align: justify;
  white-space: pre-wrap;
}
.fields input,textarea {
  width:50%;
  float:left;
  font-size:0.9em;
}
#submit {
  margin-top:2.5%;
  background-color:#4CAF50;
  color:white;
  padding:1%;
  font-size:1em;
}
.upload {
  background-color:#ff6347;
  color:white;
  font-size:0.9em;
}
.delete {
  color:white;
  font-size:0.9em;
  background-color:#DC143C;
  margin-left:1%;
}
#edit-profile {
  float:right;
}
#profile-top{
  width:100%;
  overflow: hidden;
}
#profile-top h1 {
  margin-left:1%;
}

#error {
  color:red;
}
#orderList {
  display:flex;
  justify-content:center;
}
li {
  text-align:left;
  margin-bottom:5%;
}
.gallery-wrapper {
  width:100%;
  float:left;
  display:block;
}
.caption-wrapper {
  width:50%;
  float:left;
  margin-bottom:2.5%;
}
.gallery {
  width:100%;
  float:left;
  height:30vh;
  overflow-y: scroll;
  margin-bottom:5%;
  position:relative;
}
.gallery img {
  width:80%;
  position:absolute;
  left:10%;
  top:0;
}
textarea {
  resize:none;
}
::placeholder {
  color:#696969;
}
.info {
  text-align: left;
  margin-bottom:1%;
  margin-left:0;
}
select {
  font-size:0.9em;
}

.overflow {
  overflow:hidden;
  width:30%;
  margin-left:35%;
  float:left;
}
.overflow img {
  width:100%;
}
.filepond--wrapper{
  width:50%;
}
#pictureHeading {
  float:left;
  margin-top:5%;
  display:block;
  width:100%;
}
#invite {
  width:100%;
}
#footer {
  position:absolute;
  bottom:0;
  right:0;
  background-color:white;
  padding:0% 1%;
  font-size:0.9em;
}
.red {
  color:red;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    #login {
      width:75%;
    }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    h1 {
      font-size:1.25em;
    }
    h2 {
      font-size:1em;
    }
    h3, h4 {
      font-size:0.85em;
    }
    p {
      font-size:0.75em;
    }
    small {
      font-size:0.65em;
    }
    #edit-profile{
      text-align: right;
    }
    #header-logo {
     width:10%;
    }
    #profile {
      width:96%;
      margin-left:0%;
      padding:2.5% 2%;
      float:left;
    }
    #login {
      min-width:75%;
    }
    .fields {
      display:block;
      text-align: left;
      float:left;
      width:80%;
      margin-left:10%;
      margin-bottom:2.5%;
    }
    .fields p,small {
      width:100%;
      text-align:left;
    }
    .fields input, textarea {
      width:80%;
    }
    .fields h4 {
      width:100%;
      text-align: left;
    }
    .fields a {
      width:100%;
      word-wrap:break-word;
      font-size:0.75em;
    }
    .info li {
      font-size:0.75em;
    }
    .gallery-wrapper{
      width:80%;
      margin-left:10%;
    }
    .caption-wrapper {
      width:100%;
    }
    .gallery {
      width:100%;
    }
    .gallery p {
      text-align:left;
      margin-left:0;
      margin-bottom:2.5%;
    }
    .filepond--wrapper{
    width:80%;
    float:left;
  }
}
